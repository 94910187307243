import { Box, CircularProgress, TextField, FormGroup, Tooltip, IconButton } from "@material-ui/core";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import moment from "moment";
import DatePicker from "components/inputs/DatePicker";
import style from "../../../components/inputs/styles.module.css";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import { HelpOutlineOutlined } from "@material-ui/icons";


const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    account_no: Yup.string(),
    address_line1: Yup.string().required("Address line 1 is a required field"),
    address_line2: Yup.string().required("Address line 2 is a required field"),
    address_line3: Yup.string().required("Address line 3 is a required field"),
    date_summons_served: Yup.date().required("Date summons served is a required field"),
});

const SendSummonsServed = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        account_no,
        address_line1: "",
        address_line2: "",
        address_line3: "",
        date_summons_served: "",
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSendSummonsServed = () => {
        setLoading(true);
        LssmService.sendSummonsServed({
            ...data
        }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Tooltip title="Summons were served by the court">
                    <Title>
                        Summons Served
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendSummonsServed}
                >
                    {({ errors }) => (
                        <Form fullWidth>
                            <Box mb={2} display={"flex"} justifyContent={"center"}>
                                <FormGroup>
                                    <div className={styles.title}>Writ Summons Date</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="date_summons_served"
                                        name="date_summons_served"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.date_summons_served}
                                        error={errors.date_summons_served ? true : false}
                                        helperText={errors.date_summons_served}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Address Line 1</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="address_line1"
                                        name="address_line1"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.address_line1}
                                        error={errors.address_line1 ? true : false}
                                        helperText={errors.address_line1}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>    

                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Address Line 2</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="address_line2"
                                        name="address_line2"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.address_line2}
                                        error={errors.address_line2 ? true : false}
                                        helperText={errors.address_line2}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>  

                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Address line 3</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="address_line3"
                                        name="address_line3"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.address_line3}
                                        error={errors.address_line3 ? true : false}
                                        helperText={errors.address_line3}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>  
                            <Box mb={2}  display="flex" justifyContent="center">       
                                {loading ? (
                                    <div style={{padding: "3px 30px"}}><CircularProgress /></div>
                                ) : (
                                    <BluePillButton variant="contained" type="submit">
                                        Send Summons Served
                                    </BluePillButton>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendSummonsServed;
