import BluePillButton from "components/buttons/BluePillButton";
import EditSelect from "components/table/components/EditSelect";
import { MaterialTableCurrencySettings } from "constants/locale";
import React from "react";
import * as Yup from "yup";
import CustomMaterialTable from "../../../components/table/CustomMaterialTable";
import { useMaterialTable } from "../../../providers/hooks/useMaterialTable";
import CaseService from "../../../services/caseService";
import { dateFormat } from "../../../utils/dateFormat";
import { useModal } from "providers/hooks/useModal";
import { useAuth } from "providers/hooks/useAuth";
import { Dialog, Menu, MenuItem, Toolbar } from "@material-ui/core";
import FraudMatter from "../components/FraudMatter";
import AdminSupport from "../components/AdminSupport";
import AckowledgmentOfMatter from "../components/AcknowledgmentOfMatter";
import ReturnMatterToSBSA from "../components/ReturnMatterToSBSA";
import RecallConfirmation from "../components/RecallConfirmation";
import { ArrowDropDown } from "@material-ui/icons";
import CollectionReport from "../components/CollectionReport/CollectionReport";
import SendCustomerInformation from "../components/SendCustomerInformation";
import CreateDebtorPortalAccount from "../components/CreateDebtorPortalAccount";
import SendWritExecutionServed from "../components/SendWritExecutionServed";
import SendWritExecutionIssuedDate from "../components/SendWritExecutionIssuedDate";
import SendDebtorShortTermEmployed from "../components/SendDebtorShortTermEmployed";
import SendWritExecutionRequestDate from "../components/SendWritExecutionRequestDate";
import SendSummonsRequested from "../components/SendSummonsRequested";
import SendMaritalType from "../components/SendMaritalType";
import SendSummonsServed from "../components/SendSummonsServed";
import SendSummonsDocumentsReceived from "../components/SendSummonsDocumentsReceived";
import SendRecommendedLegalAction from "../components/SendRecommendedLegalAction";
import SendJudgementApplication from "../components/SendJudgementApplication";
import SendDebtorDeceased from "../components/SendDebtorDeceased";
import SendSummonsNotServed from "../components/SendSummonsNotServed";
import SendDefendedMatter from "../components/SendDefendedMatter";
import SendReturnOfService from "../components/SendReturnOfService";
import SendDebtorUnemployed from "../components/SendDebtorUnemployed";
import SendDebtorEmigrated from "../components/SendDebtorEmigrated";
import SendDebtorRetrenched from "../components/SendDebtorRetrenched";
import SendAdminOrderFaxed from "../components/SendAdminOrderFaxed";
import SendJudgementGrantedDetail from "../components/SendJudgementGrantedDetail";
import SendWritOfExecutionNonServiceDate from "../components/SendWritOfExecutionNonServiceDate";
import FinancialTransactions from "../components/FinancialTransactions/FinancialTransactions";
import SendAccountSelectionChoice from "../components/SendAccountSelectionChoice";
import SendDebtorDebtorOnTrace from "../components/SendDebtorDebtorOnTrace";
import SendFreeFormatReview from "../components/SendFreeFormatReview";
import SendReasonForNonPayment from "../components/SendReasonForNonPayment";

const schema = Yup.object().shape({
    date: Yup.date().required(),
    code: Yup.string().required(),
    description: Yup.object()
        .shape({
            id: Yup.string(),
        })
        .required("Please select an description"),
    duration: Yup.string(),
    amount: Yup.string(),
    running_total: Yup.string(),
    type: Yup.object()
        .shape({
            id: Yup.string(),
        })
        .required("Please select an type."),
    capture_date: Yup.string(),
    // unique_id: Yup.string(),
});

function FeesTable({
    account_status,
    account_no,
    agent_id,
    recall_confirmed,
    matter_acknowledged,
    matter_returned,
    debtor_name,
    debtor_id,
    debtor_email,
    accountDetailsList,
}) {
    const { user_type_id } = useAuth();
    const { setModal, unsetModal, setToast } = useModal();
    const [descriptions, setDescriptions] = React.useState([]);
    const [menuAnchor, setMenuAnchor] = React.useState();
    const [types, setTypes] = React.useState([]);
    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
        endpoint: (params) =>
            new Promise((resolve, reject) => {
                CaseService.debtorCaseFees(params).then((response) => {
                    if (response.data?.descriptions != null) {
                        setDescriptions([...response.data.descriptions]);
                    }
                    if (response.data?.types != null) {
                        setTypes([...response.data.types]);
                    }

                    // resolve promise with table data
                    resolve({
                        status: response.status,
                        data: response.data.fees,
                        count: response.data.count,
                        pagination: response.pagination,
                    });
                });
            }),
        initParams: {
            account_no,
        },
    });

    console.log("RER", accountDetailsList);

    const handleSendForTracing = () => {
        CaseService.addDebtorCaseNote({
            account_no: account_no,
            agent_id: agent_id,
            category: { id: 3 },
            status: { id: 1 },
            type: { id: 4 },
            amount: 0,
            note: "Debtor account sent for tracing",
            note_date: new Date(),
            tracing: 1,
        })
            .then(() => {
                setToast("Tracing request sent successfully", "success");
            })
            .catch((error) => {
                //console.log("send for tracing error", error)
                setToast("Something went wrong while sending for tracing, contact support", "error");
            });
    };

    const check = accountDetailsList.recall_confirmed > 0 || accountDetailsList.fraud_matter > 0 ? true : false;

    const handleSBSAAction = (e) => {
        setMenuAnchor(e.target);
    };

    const handleActionTypeSelect = (e) => {
        setMenuAnchor(false);

        switch (e.target.id) {
            case "financial_transactions":
                setModal({ 
                    Component: <FinancialTransactions account_no={account_no} unsetModal={unsetModal} />,
                });
                break;

            case "account-selection-choice":
                setModal({ 
                    Component: <SendAccountSelectionChoice account_no={account_no} unsetModal={unsetModal} />,
                });
                break;

            case "debtor-on-trace":
                setModal({ 
                    Component: <SendDebtorDebtorOnTrace account_no={account_no} unsetModal={unsetModal} />,
                });
                break;

            case "free-format-for-account-review":
                setModal({ 
                    Component: <SendFreeFormatReview account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-judgement-granted-detail":
                setModal({ 
                    Component: <SendJudgementGrantedDetail account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-writ-of-execution-non-service-date":
                setModal({ 
                    Component: <SendWritOfExecutionNonServiceDate account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-summons-not-served":
                setModal({ 
                    Component: <SendSummonsNotServed account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-defended-matter":
                setModal({ 
                    Component: <SendDefendedMatter account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "reason-for-non-payment":
                setModal({ 
                    Component: <SendReasonForNonPayment account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-return-of-service":
                setModal({ 
                    Component: <SendReturnOfService account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-debtor-unemployed":
                setModal({ 
                    Component: <SendDebtorUnemployed account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-debtor-emigrated":
                setModal({ 
                    Component: <SendDebtorEmigrated account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-debtor-retrenched":
                setModal({ 
                    Component: <SendDebtorRetrenched account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-admin-order-faxed":
                setModal({ 
                    Component: <SendAdminOrderFaxed account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-summons-documents-received":
                setModal({
                    Component: <SendSummonsDocumentsReceived account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-recommended-legal-action":
                setModal({
                    Component: <SendRecommendedLegalAction account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-judgement-application":
                setModal({
                    Component: <SendJudgementApplication account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-debtor-deceased":
                setModal({ 
                    Component: <SendDebtorDeceased account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-marital-type":
                setModal({
                    Component: <SendMaritalType account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-summons-requested":
                setModal({
                    Component: <SendSummonsRequested account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-writ-execution-request-date":
                setModal({
                    Component: <SendWritExecutionRequestDate account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-debtor-short-term-employed":
                setModal({
                    Component: <SendDebtorShortTermEmployed account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-writ-execution-issued-date":
                setModal({
                    Component: <SendWritExecutionIssuedDate account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-writ-execution-served":
                setModal({
                    Component: <SendWritExecutionServed account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "send-summons-served":
                setModal({
                    Component: <SendSummonsServed account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "fraud-matter":
                setModal({
                    Component: <FraudMatter account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "admin-support":
                setModal({
                    Component: <AdminSupport account_no={account_no} unsetModal={unsetModal} />,
                });
                break;
            case "new-matter-acknowledgement":
                setModal({
                    Component: (
                        <AckowledgmentOfMatter
                            account_no={account_no}
                            unsetModal={unsetModal}
                            matter_acknowledged={matter_acknowledged}
                        />
                    ),
                    size: "sml",
                });
                break;
            case "recall-confirmation":
                setModal({
                    Component: (
                        <RecallConfirmation
                            account_no={account_no}
                            unsetModal={unsetModal}
                            recall_confirmed={recall_confirmed}
                        />
                    ),
                    size: "sml",
                });
                break;
            case "return-matter-to-sbsa":
                setModal({
                    Component: (
                        <ReturnMatterToSBSA
                            account_no={account_no}
                            unsetModal={unsetModal}
                            matter_returned={matter_returned}
                        />
                    ),
                    size: "sml",
                });
                break;
            case "send-customer-information":
                setModal({
                    Component: <SendCustomerInformation account_no={account_no} unsetModal={unsetModal} />,
                    size: "sml",
                });
                break;
            case "other":
                break;
            default:
                break;
        }
    };

    const ACTIONS = [
        {
            icon: () => (
                <>
                    <BluePillButton variant="contained" style={{ overflow: "hidden", marginRight: "10px" }}>
                        Send For Tracing
                    </BluePillButton>
                </>
            ),
            tooltip: "Send For Tracing",
            isFreeAction: true,
            onClick: () => handleSendForTracing(),
        },

        {
            icon: () => (
                <div>
                    <Toolbar style={{ position: "relative" }}>
                        <BluePillButton
                            variant="contained"
                            onClick={handleSBSAAction}
                            style={{ overflow: "hidden", marginRight: "10px" }}
                            disabled={check}
                        >
                            SBSA Actions <ArrowDropDown />
                        </BluePillButton>
                        <Menu
                            anchorEl={menuAnchor}
                            open={Boolean(menuAnchor)}
                            keepMounted
                            onClose={() => setMenuAnchor(undefined)}
                            style={{
                                position: "absolute",
                                top: "40%",
                                left: "20%",
                            }}
                        >
                            {user_type_id <= 4  && (<MenuItem id="financial_transactions" onClick={handleActionTypeSelect}>
                                Financial Transactions
                            </MenuItem>)}
                            <MenuItem id="account-selection-choice" onClick={handleActionTypeSelect}>
                                Send Account Selection Choice
                            </MenuItem>
                            <MenuItem id="debtor-on-trace" onClick={handleActionTypeSelect}>
                                Send Debtor On Trace
                            </MenuItem>
                            <MenuItem id="free-format-for-account-review" onClick={handleActionTypeSelect}>
                                Send Free Format For Account Review
                            </MenuItem>
                            <MenuItem id="send-judgement-granted-detail" onClick={handleActionTypeSelect}>
                                Send Judgement Granted Detail
                            </MenuItem>
                            <MenuItem id="send-writ-of-execution-non-service-date" onClick={handleActionTypeSelect}>
                                Send Writ Of Execution Non Service Date
                            </MenuItem>
                            <MenuItem id="send-summons-not-served" onClick={handleActionTypeSelect}>
                                Send Summons Not Served
                            </MenuItem> 
                            <MenuItem id="send-defended-matter" onClick={handleActionTypeSelect}>
                                Send Defended Matter
                            </MenuItem>
                            <MenuItem id="send-return-of-service" onClick={handleActionTypeSelect}>
                                Send Return Of Service
                            </MenuItem>
                            <MenuItem id="send-debtor-unemployed" onClick={handleActionTypeSelect}>
                                Send Debtor Unemployed
                            </MenuItem>
                            <MenuItem id="send-debtor-emigrated" onClick={handleActionTypeSelect}>
                                Send Debtor Emigrated
                            </MenuItem> 
                            <MenuItem id="send-debtor-retrenched" onClick={handleActionTypeSelect}>
                                Send Debtor Retrenched
                            </MenuItem>
                            <MenuItem id="send-admin-order-faxed" onClick={handleActionTypeSelect}>
                                Send Admin Order Faxed
                            </MenuItem>
                            <MenuItem id="send-summons-documents-received" onClick={handleActionTypeSelect}>
                                Send Summons Documents Received
                            </MenuItem>
                            <MenuItem id="send-recommended-legal-action" onClick={handleActionTypeSelect}>
                                Send Recommended Legal Action
                            </MenuItem>
                            <MenuItem id="send-judgement-application" onClick={handleActionTypeSelect}>
                                Send Judgement Application
                            </MenuItem>
                            <MenuItem id="send-debtor-deceased" onClick={handleActionTypeSelect}>
                                Send Debtor Deceased
                            </MenuItem>
                            <MenuItem id="send-marital-type" onClick={handleActionTypeSelect}>
                                Send Marital Type
                            </MenuItem>
                            <MenuItem id="send-summons-requested" onClick={handleActionTypeSelect}>
                                Send Summons Requested
                            </MenuItem>
                            <MenuItem id="send-writ-execution-request-date" onClick={handleActionTypeSelect}>
                                Send Writ Execution Request Date
                            </MenuItem>
                            <MenuItem id="send-debtor-short-term-employed" onClick={handleActionTypeSelect}>
                                Send Debtor Short Term Employed
                            </MenuItem>
                            <MenuItem id="send-writ-execution-issued-date" onClick={handleActionTypeSelect}>
                                Send Writ Execution Issued Date
                            </MenuItem>
                            <MenuItem id="send-writ-execution-served" onClick={handleActionTypeSelect}>
                                Send Writ Execution Served
                            </MenuItem>
                            <MenuItem id="send-summons-served" onClick={handleActionTypeSelect}>
                                Send Summons Served
                            </MenuItem>
                            <MenuItem id="reason-for-non-payment" onClick={handleActionTypeSelect}>
                                Send Reason For Non-Payment
                            </MenuItem>
                            <MenuItem id="fraud-matter" onClick={handleActionTypeSelect}>
                                Report Fraud Matter
                            </MenuItem>
                            <MenuItem id="admin-support" onClick={handleActionTypeSelect}>
                                Admin Support
                            </MenuItem>
                            <MenuItem id="new-matter-acknowledgement" onClick={handleActionTypeSelect}>
                                New Matter Acknowledgement
                            </MenuItem>
                            <MenuItem id="recall-confirmation" onClick={handleActionTypeSelect}>
                                Recall Confirmation
                            </MenuItem>
                            <MenuItem id="return-matter-to-sbsa" onClick={handleActionTypeSelect}>
                                Return Matter to SBSA
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </div>
            ),
            tooltip: "SBSA Actions",
            isFreeAction: true,
        },
        {
            icon: () => (
                <>
                    <BluePillButton variant="contained" style={{ overflow: "hidden", marginRight: "10px" }}>
                        Collection Report
                    </BluePillButton>
                </>
            ),
            tooltip: "Collection Report",
            isFreeAction: true,
            onClick: () =>
                setModal({
                    Component: <CollectionReport account_no={account_no} unsetModal={unsetModal} />,
                }),
        },
        {
            icon: () => (
                <>
                    <BluePillButton variant="contained" style={{ overflow: "hidden", marginRight: "10px" }}>
                        Create Debtor Portal Account
                    </BluePillButton>
                </>
            ),
            tooltip: "Create Debtor Portal Account",
            isFreeAction: true,
            onClick: () =>
                setModal({
                    Component: (
                        <CreateDebtorPortalAccount
                            account_no={account_no}
                            debtor_name={debtor_name}
                            debtor_id={debtor_id}
                            debtor_email={debtor_email}
                            accountDetailsList={accountDetailsList}
                            unsetModal={unsetModal}
                        />
                    ),
                    size: "sml",
                }),
        },
    ];

    const ICONS = {
        Add: (props, ref) => (
            <>
                <BluePillButton ref={ref} variant="contained" style={{ overflow: "hidden", marginRight: "10px" }}>
                    + Fee
                </BluePillButton>
            </>
        ),
    };

    const EDITABLE = {
        onRowUpdate: (newRow) =>
            CaseService.updateDebtorCaseFee({
                account_no,
                agent_id,
                ...newRow,
            }),
        onRowDelete: (oldRow) =>
            CaseService.deleteDebtorCaseFee({
                id: oldRow.id,
            }),
        onRowAdd: (newRow) =>
            CaseService.addDebtorCaseFee({
                account_no,
                agent_id,
                ...newRow,
            }),
    };

    return (
        <>
            <CustomMaterialTable
                title={null}
                tableRef={tableRef}
                columns={[
                    { title: "ID", field: "id", hidden: true },
                    {
                        title: "Date",
                        field: "date",
                        render: (row) => (row?.date != null ? dateFormat(row.date) : "-"),
                        type: "date",
                        initialEditValue: new Date(),
                    },
                    {
                        title: "Code",
                        field: "code",
                    },
                    {
                        title: "Description",
                        field: "description",
                        editComponent: (params) => <EditSelect params={params} options={descriptions} />,
                        render: (row) => row.description?.name,
                    },
                    {
                        title: "Duration (H)",
                        field: "duration",
                        type: "numeric",
                    },
                    {
                        title: "Amount",
                        field: "amount",
                        type: "currency",
                        currencySetting: MaterialTableCurrencySettings,
                    },
                    {
                        title: "Running Total",
                        field: "running_total",
                        type: "currency",
                        currencySetting: MaterialTableCurrencySettings,
                    },
                    {
                        title: "Type",
                        field: "type",
                        align: "center",
                        editComponent: (params) => <EditSelect params={params} options={types} />,
                        render: (row) => row.type?.name,
                    },
                    {
                        title: "Capture Date",
                        field: "capture_date",
                        render: (row) => (row?.capture_date != null ? dateFormat(row.capture_date) : "-"),
                        type: "date",
                    },
                ]}
                validationSchema={schema}
                data={remoteDataSource}
                editable={account_status === 1 && user_type_id !== 4 && EDITABLE}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    toolbarButtonAlignment: "left",
                }}
                icons={account_status === 1 && user_type_id !== 4 && ICONS}
                actions={account_status === 1 && ACTIONS}
            />
        </>
    );
}

export default FeesTable;
