import { Box, CircularProgress, FormGroup, Grid, TextField, FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import BluePillButton from "components/buttons/BluePillButton";
import TextArea from "components/inputs/TextArea";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import * as Yup from "yup";
import style from "../../../components/inputs/styles.module.css";
import { ArrowDown } from "../../../common/Layouts";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import { HelpOutlineOutlined } from '@material-ui/icons'; // Import HelpOutlineOutlined icon from Material-UI


const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    amount: Yup.string()
    .required("Amount is required")
    .matches(/^\d+(\.\d{1,2})?$/, 'Invalid amount format. Use up to 2 decimal places.'),
counter_claim_amount: Yup.string()
    .required("Counter claim amount is required")
    .matches(/^\d+(\.\d{1,2})?$/, 'Invalid counter claim amount format. Use up to 2 decimal places.'),
});


const SendDefendedMatter = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [declarationReplyChecked, setDeclarationReplyChecked] = useState(false);
    const [declarationReceivedChecked, setDeclarationReceivedChecked] = useState(false);
    const [noticeOfBarIssuedChecked, setNoticeOfBarIssuedChecked] = useState(false);
    const [rescissionApplicationReceivedChecked, setRescissionApplicationReceivedChecked] = useState(false);
    const [rescissionOpposedStatusChecked, setRescissionOpposedStatusChecked] = useState(false);
    const [opposingAffidavitIssuedChecked, setOpposingAffidavitIssuedChecked] = useState(false);
    const [rescissionGrantedChecked, setRescissionGrantedChecked] = useState(false);

    const [loading, setLoading] = useState(false);
    const [display, setDisplay] = useState({
        init: true,        
        defended_information: false
    });

    const [data, setData] = useState({
        account_no,
        case_no: "",
        court_date: "",
        amount: "",
        declaration_filed_date: "",
        plea_entered_date: "",
        declaration_received: "N",
        declaration_reply: "N",
        notice_of_bar_issued: "N",
        notice_of_bar_issued_date: "",
        counter_claim_amount: "",
        trial_request_date: "",
        pre_trial_date: "",
        trial_date_set_date: "",
        trial_verdict_date: "",
        rescission_application_rec: "N",
        rescission_reason: "",
        rescission_rec_date: "",
        rescission_opposed_status: "N",
        opposing_affidavit_issued: "N",
        rescission_granted: "N"
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleDeclarationReplyChecked = (e) => {
        setDeclarationReplyChecked(e.target.checked);
        !declarationReplyChecked
          ? setData({ ...data, declaration_reply: "Y"})
          : setData({ ...data, declaration_reply: "N" });
    };

    const handleDeclarationReceivedChecked= (e) => {
        setDeclarationReceivedChecked(e.target.checked);
        !declarationReceivedChecked
          ? setData({ ...data, declaration_received: "Y"})
          : setData({ ...data, declaration_received: "N" });
    };

    const handleNoticeOfBarIssuedChecked= (e) => {
        setNoticeOfBarIssuedChecked(e.target.checked);
        !noticeOfBarIssuedChecked
          ? setData({ ...data, notice_of_bar_issued: "Y"})
          : setData({ ...data, notice_of_bar_issued: "N" });
    };

    const handleRescissionApplicationReceivedChecked = (e) => {
        setRescissionApplicationReceivedChecked(e.target.checked);
        !rescissionApplicationReceivedChecked
          ? setData({ ...data, rescission_application_rec: "Y"})
          : setData({ ...data, rescission_application_rec: "N" });
    };

    const handleRescissionOpposedStatusChecked = (e) => {
        setRescissionOpposedStatusChecked(e.target.checked);
        !rescissionOpposedStatusChecked
          ? setData({ ...data, rescission_opposed_status: "Y"})
          : setData({ ...data, rescission_opposed_status: "N" });
    };

    const handleOpposingAffidavitIssuedChecked= (e) => {
        setOpposingAffidavitIssuedChecked(e.target.checked);
        !opposingAffidavitIssuedChecked
          ? setData({ ...data, opposing_affidavit_issued: "Y"})
          : setData({ ...data, opposing_affidavit_issued: "N" });
    };

    const handleRescissionGrantedChecked= (e) => {
        setRescissionGrantedChecked(e.target.checked);
        !rescissionGrantedChecked
          ? setData({ ...data, rescission_granted: "Y"})
          : setData({ ...data, rescission_granted: "N" });
    };


    const handleSendDefendedMatter = () => {
        setLoading(true);
        LssmService.sendDefendedMatter({
            ...data
        }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    const isFormValid = () => {
        for (let key in data) {
            if (!data[key]) {
                return true; 
            }
        }
        
        return false;
    };

    const isFormPartyOneValid = () => {

        const { 
            pre_trial_date,
            trial_request_date,
            case_no,
            trial_date_set_date,
            trial_verdict_date,
            plea_entered_date,
            rescission_reason,
            rescission_rec_date,
            rescission_opposed_status,
            opposing_affidavit_issued,
        } = data;
    
        const extractedData = {
            pre_trial_date,
            trial_request_date,
            case_no,
            trial_date_set_date,
            trial_verdict_date,
            plea_entered_date,
            rescission_reason,
            rescission_rec_date,
            rescission_opposed_status,
            opposing_affidavit_issued
        };

        for (let key in extractedData) {
            if (!data[key]) {
                return true; 
            }
        }        
        return false;
    };

    const isFormPartyTwoValid = () => {
        const { 
            counter_claim_amount,
            declaration_filed_date,
            court_date,
            notice_of_bar_issued_date,
            amount,
            declaration_reply,
            declaration_received,
            notice_of_bar_issued,
            rescission_application_rec,
            rescission_granted
        } = data;
    
        const extractedData = {
            counter_claim_amount,
            declaration_filed_date,
            court_date,
            notice_of_bar_issued_date,
            amount,
            declaration_reply,
            declaration_received,
            notice_of_bar_issued,
            rescission_application_rec,
            rescission_granted
        };

        for (let key in extractedData) {
            if (!data[key]) {
                return true; 
            }
        }        
        return false;
    };

    const handleAddPartyOne = () => {
        setDisplay({ ...display, defended_information: true, init: false});
    };


    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Title>Defended Matter</Title>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendDefendedMatter}
                >
                    {({ errors }) => (
                        <Form fullWidth>
                            {display.init && (
                            <>
                            <Grid container spacing={2}>
                              
                                <Grid item xs={12} sm={6}>
                                    <Box mb={2}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch checked={rescissionOpposedStatusChecked} color="primary" onChange={handleRescissionOpposedStatusChecked} />}
                                                label="Status of the rescission. Is it opposed?"
                                            />
                                        </FormGroup>
                                    </Box>
                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                <div className={styles.title}>Pre Trial Date</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="date"
                                                        id="pre_trial_date"
                                                        name="pre_trial_date"
                                                        value={data.pre_trial_date}
                                                        error={errors.pre_trial_date ? true : false}
                                                        helperText={errors.pre_trial_date}
                                                        onChange={handleChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        
                                                    />
                                            </div>
                                        </FormGroup>
                                    </Box>

                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                    <div className={styles.title}>Trial Request Date</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="date"
                                                        id="trial_request_date"
                                                        name="trial_request_date"
                                                        value={data.trial_request_date}
                                                        error={errors.trial_request_date ? true : false}
                                                        helperText={errors.trial_request_date}
                                                        onChange={handleChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        
                                                    />
                                            </div>
                                        </FormGroup>
                                    </Box>

                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                <div className={styles.title}>Case Number</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="case_no"
                                                        name="case_no"
                                                        value={data.case_no}
                                                        error={errors.case_no ? true : false}
                                                        helperText={errors.case_no}
                                                        onChange={handleChange}
                                                        
                                                    />
                                            </div>
                                        </FormGroup>
                                    </Box>
                         
                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                <div className={styles.title}>Trial Request Date</div>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    type="date"
                                                    id="trial_date_set_date"
                                                    name="trial_date_set_date"
                                                    value={data.trial_date_set_date}
                                                    error={errors.trial_date_set_date ? true : false}
                                                    helperText={errors.trial_date_set_date}
                                                    onChange={handleChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}  
                                                />
                                            </div>
                                        </FormGroup>
                                    </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>

                                    <Box mb={2} >
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch checked={rescissionApplicationReceivedChecked} color="primary" onChange={handleRescissionApplicationReceivedChecked} />}
                                                label="Is the opposition affidavit issued?"
                                            />
                                        </FormGroup>
                                    </Box>

                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                <div className={styles.title}>Trial Verdict Date</div>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    type="date"
                                                    id="trial_verdict_date"
                                                    name="trial_verdict_date"
                                                    value={data.trial_verdict_date}
                                                    error={errors.trial_verdict_date ? true : false}
                                                    helperText={errors.trial_verdict_date}
                                                    onChange={handleChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    
                                                />
                                            </div>
                                        </FormGroup>
                                    </Box>

                                <Box mb={2}>
                                    <FormGroup>
                                    <div className={style.wrapper_input}>
                                            <div className={styles.title}>Plea Entered Date</div>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                type="date"
                                                id="plea_entered_date"
                                                name="plea_entered_date"
                                                value={data.plea_entered_date}
                                                error={errors.plea_entered_date ? true : false}
                                                helperText={errors.plea_entered_date}
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                
                                            />
                                    </div>
                                    </FormGroup>
                                </Box>
                            
                                <Box mb={2}>
                                    <FormGroup>
                                        <div className={style.wrapper_input}>
                                                <div className={styles.title}>Rescission Reason</div>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="rescission_reason"
                                                    name="rescission_reason"
                                                    value={data.rescission_reason}
                                                    error={errors.rescission_reason ? true : false}
                                                    helperText={errors.rescission_reason}
                                                    onChange={handleChange}
                                                    
                                                />
                                        </div>
                                    </FormGroup>
                                </Box>
                        

                                <Box mb={2}>
                                <FormGroup>
                                <div className={style.wrapper_input}>
                                        <div className={styles.title}>Rescission Received Date</div>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            type="date"
                                            id="rescission_rec_date"
                                            name="rescission_rec_date"
                                            value={data.rescission_rec_date}
                                            error={errors.rescission_rec_date ? true : false}
                                            helperText={errors.rescission_rec_date}
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            
                                        />
                                </div>
                                    </FormGroup>
                                </Box>

                                </Grid>
                                </Grid>
                            </>
                            )}
                            {display.defended_information && (
                            <>

                            <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>


                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Notice of Bar issued Date</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="notice_of_bar_issued_date"
                                        name="notice_of_bar_issued_date"
                                        value={data.notice_of_bar_issued_date}
                                        error={errors.notice_of_bar_issued_date ? true : false}
                                        helperText={errors.notice_of_bar_issued_date}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                            <div className={styles.title}>Amount</div>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="amount"
                                                name="amount"
                                                value={data.amount}
                                                error={errors.amount ? true : false}
                                                helperText={errors.amount}
                                                onChange={handleChange}
                                                
                                            />
                                    </div>
                                </FormGroup>
                            </Box>
                    

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Counter Claim Amount</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="counter_claim_amount"
                                        name="counter_claim_amount"
                                        value={data.counter_claim_amount}
                                        error={errors.counter_claim_amount ? true : false}
                                        helperText={errors.counter_claim_amount}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Declaration Filed Date</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="declaration_filed_date"
                                        name="declaration_filed_date"
                                        value={data.declaration_filed_date}
                                        error={errors.declaration_filed_date ? true : false}
                                        helperText={errors.declaration_filed_date}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                        
                            <Box mb={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={declarationReceivedChecked} color="primary" onChange={handleDeclarationReceivedChecked} />}
                                        label="Was the declaration received?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={noticeOfBarIssuedChecked} color="primary" onChange={handleNoticeOfBarIssuedChecked} />}
                                        label="Is the notice of Bar issued?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={declarationReplyChecked} color="primary" onChange={handleDeclarationReplyChecked} />}
                                        label="The reply on the declaration?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={opposingAffidavitIssuedChecked} color="primary" onChange={handleOpposingAffidavitIssuedChecked} />}
                                        label="Is the opposition affidavit issued?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={3.2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={rescissionGrantedChecked} color="primary" onChange={handleRescissionGrantedChecked} />}
                                        label="Is the rescission granted?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Court Date</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="court_date"
                                        name="court_date"
                                        value={data.court_date}
                                        error={errors.court_date ? true : false}
                                        helperText={errors.court_date}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>

                            </Grid>
                     
                            </>
                            )}
                         

                            <Box  display="flex" justifyContent="center">       
                                {loading ? (
                                    <div style={{padding: "3px 30px"}}><CircularProgress /></div>
                                ) : (
                                    <>

                                    {display.init && (
                                    <BluePillButton variant="contained" onClick={handleAddPartyOne} disabled={isFormPartyOneValid()}>
                                        Next
                                     </BluePillButton>
                                    )}

                                    {display.defended_information && (
                                    <BluePillButton variant="contained" type="submit" disabled={isFormValid()}>
                                        Send Debtor Deceased
                                    </BluePillButton>
                                     )}
                                    </>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendDefendedMatter;
