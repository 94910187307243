import React, { useState } from 'react';
import { Box, CircularProgress, TextField, FormGroup, Tooltip, IconButton } from '@material-ui/core';
import BluePillButton from 'components/buttons/BluePillButton';
import styled from 'styled-components';
import { useModal } from 'providers/hooks/useModal';
import LssmService from 'api/services/lssmService';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import styles from './CollectionReport/styles.module.css';
import TextArea from 'components/inputs/TextArea';
import { HelpOutlineOutlined } from '@material-ui/icons'; // Import HelpOutlineOutlined icon from Material-UI

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: 'Avenir Next Bold';
    span {
        font-weight: normal;
        font-family: 'Avenir Next Medium';
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    account_no: Yup.string(),
    return_of_service_date: Yup.date().required('Return of service date is a required field'),
    comment: Yup.string().required('Comment is a required field'),
});

const SendReturnOfService = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        account_no,
        return_of_service_date: '',
        comment: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        // Enforce character limit for comment field
        if (name === 'comment' && value.length > 40) {
            setToast("Comment must be 40 characters or less.", 'error');

            return; // do not update state if exceeded limit
        }
        setData({ ...data, [name]: value });
    };

    const handleSendReturnOfService = () => {
        setLoading(true);
        LssmService.sendReturnOfService({
            ...data
        }).then((response) => {
            switch (response.status) {
                case 'success':
                    setToast(response.message, 'success');
                    setLoading(false);
                    unsetModal();
                    break;
                case 'error':
                    setToast(response.message, 'error');
                    break;
                default:
                    break;
            }
        });
    };

    return (
        <Box display={'flex'} justifyContent={'center'} flexDirection="column" padding={'2em'}>
            <Box mb={6} display={'flex'} justifyContent={'center'}>
                <Tooltip title="Sent when Service of Summons is returned">
                    <Title>
                        Return of Service
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendReturnOfService}
                >
                    {({ errors }) => (
                        <Form fullWidth>
                            <Box mb={2} display={'flex'} justifyContent={'center'}>
                                <FormGroup>
                                    <div className={styles.title}>Return of Service Date</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="return_of_service_date"
                                        name="return_of_service_date"
                                        customStyle={{
                                            justifyContent: 'flex-start',
                                        }}
                                        value={data.return_of_service_date}
                                        error={errors.return_of_service_date ? true : false}
                                        helperText={errors.return_of_service_date}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Comment</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="comment"
                                        name="comment"
                                        customStyle={{
                                            justifyContent: 'flex-start',
                                        }}
                                        value={data.comment}
                                        error={errors.comment ? true : false}
                                        helperText={errors.comment}
                                        onChange={handleChange}
                                        isSearch={false}
                                        inputProps={{ maxLength: 40 }} // Added maxLength attribute
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2} display="flex" justifyContent="center">
                                {loading ? (
                                    <div style={{ padding: '3px 30px' }}>
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <BluePillButton variant="contained" type="submit">
                                        Send Return of Service
                                    </BluePillButton>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendReturnOfService;
