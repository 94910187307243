import { Box, CircularProgress, FormGroup, FormControlLabel, Switch, Tooltip, IconButton, TextField } from "@material-ui/core";
import TextArea from "components/inputs/TextArea";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import * as Yup from "yup";
import style from "../../../components/inputs/styles.module.css";
import { ArrowDown } from "../../../common/Layouts";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import { HelpOutlineOutlined } from "@material-ui/icons";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    account_no: Yup.string(),
    rla_action: Yup.string().required("RLA action is a required field"),
    rla_reason: Yup.string().required("RLA reason is a required field"),
    rla_comment_1: Yup.string().required("RLA comment 1 is a required field"),
    rla_comment_2: Yup.string().required("RLA comment 2 is a required field"),
});

const SendRecommendedLegalAction = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        account_no,
        rla_action: "",
        rla_reason: "",
        rla_comment_1: "",
        rla_comment_2: ""
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSendRecommendedLegalAction = () => {
        setLoading(true);
        LssmService.sendRecommendedLegalAction({
            ...data
        }).then((response) => {
            setLoading(false);
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    const handleChecked = (e) => {
        setChecked(e.target.checked);
        setData({ ...data, rla_action: e.target.checked ? "Y" : "N" });
    };

    const isFormValid = () => {
        for (let key in data) {
            if (!data[key]) {
                return true; 
            }
        }
        
        return false;
    };
    
    

    return (
        <Box display="flex" justifyContent="center" flexDirection="column" padding="2em">
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Tooltip title="Sent when legal action for listed reasons is recommended">
                    <Title>
                        Recommended Legal Action
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendRecommendedLegalAction}
                >
                    {({ errors, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} fullWidth>
                            <Box mb={2} display="flex" justifyContent="center">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={checked} color="primary" onChange={handleChecked} />}
                                        label="Recommend legal Action?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2} display="flex" justifyContent="center">
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Recommended legal Action Reason</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={!!errors.rla_reason}
                                                id="rla_reason"
                                                name="rla_reason"
                                                helperText={errors.rla_reason}
                                                className={"select-input-e4"}
                                                value={data.rla_reason}
                                                onChange={handleChange}
                                            >
                                                <option value="" label="Select RLA Reason">Select RLA Reason</option>
                                                <option value="1" label="Unable to trace customer">Unable to trace customer</option>
                                                <option value="2" label="Customer not willing to cooperate">Customer not willing to cooperate</option>
                                                <option value="3" label="Customer cannot afford; not willing to sell">Customer cannot afford; not willing to sell</option>
                                                <option value="4" label="Divorce; awaiting a court order">Divorce; awaiting a court order</option>
                                                <option value="5" label="Insolvent or debt review not approved">Insolvent or debt review not approved</option>
                                                <option value="6" label="Arrangements not kept">Arrangements not kept</option>
                                                <option value="7" label="Other">Other (Explain in comments)</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Comment One</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="rla_comment_1"
                                        name="rla_comment_1"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.rla_comment_1}
                                        error={!!errors.rla_comment_1}
                                        helperText={errors.rla_comment_1}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Comment Two</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="rla_comment_2"
                                        name="rla_comment_2"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.rla_comment_2}
                                        error={!!errors.rla_comment_2}
                                        helperText={errors.rla_comment_2}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2} display="flex" justifyContent="center">
                                {loading ? (
                                    <div style={{ padding: "3px 30px" }}><CircularProgress /></div>
                                ) : (
                                    <BluePillButton variant="contained" type="submit">
                                        Send Recommended Legal Action
                                    </BluePillButton>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendRecommendedLegalAction;
