import { Chip, Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AgentService } from "api/services";
import BluePillButton from "components/buttons/BluePillButton";
import { LocaleSettings } from "constants/locale";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../constants/styles";
import legal_fees_icon from "../../assets/icons/FontAwsome (balance-scale).png";
import settlement_icon from "../../assets/icons/Layer -10.png";
import capital_icon from "../../assets/icons/Layer -11.png";
import payments_icon from "../../assets/icons/Layer -12.png";
import fees_icon from "../../assets/icons/Layer -13.png";
import interest_icon from "../../assets/icons/Layer -9.png";
import { Box, ButtonBack, TitleSection } from "../../common/Layouts";
import DebtorAccountDetails from "../../components/DebtorAccountDetails";
import EmploymentDetails from "../../components/DebtorAccountDetails/EmploymentDetails";
import SwitchComponent from "../../components/SwitchComponent";
import CaseService from "../../services/caseService";
import AdditionalInfo from "./Additional";
import Contacts from "./Contacts";
import style from "./Debtor.module.css";
import Notes from "./Notes";
import Ptp from "./Ptp";
import Fees from "./tables/FeesTable";
import { DEBTOR, DASHBOARD } from "../../constants/pathNames";
import { useModal } from "providers/hooks/useModal";
import AttachmentInformation from "components/DebtorAccountDetails/AttachmentInformation";
import TabsComponent from "components/TabsComponent";
import { Flag } from "@material-ui/icons";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const NextDebtorButton = styled(BluePillButton)`
    overflow: hidden;
    float: right;
    top: 10px;
    color: ${colors.orange};
    text-transform: uppercase;
    fontweight: bold;
`;

function DebtorDetail() {
    const history = useHistory();
    const { setToast } = useModal();
    const authState = useSelector((state) => state.auth);
    const { state: locationState } = useLocation();
    const [loading, setLoading] = React.useState(true);
    const configList = [
        "account details",
        "contacts",
        "notes",
        "employment details",
        "additional information",
        "ptp",
        "attachment information",
    ];
    const [activeItem, setActiveItem] = useState(configList[0]);
    const [debtorSummaryList, setDebtorSummaryList] = React.useState({
        capital: { icon: capital_icon, value: null, title: "capital" },
        interest: {
            icon: interest_icon,
            value: null,
            title: "interest",
        },
        fees: { icon: fees_icon, value: 0, title: "fees" },
        legal_fees: {
            icon: legal_fees_icon,
            value: null,
            title: "legal fees",
        },
        payments: {
            icon: payments_icon,
            value: null,
            title: "payments",
        },
        settlement: {
            icon: settlement_icon,
            value: null,
            title: "settlement",
        },
    });
    const [accountDetailsList, setAccountDetailsList] = React.useState({});
    const [linkedAccounts, setLinkedAccounts] = React.useState([]);
    const [tracingUpdate, setTracingUpdate] = React.useState(false);

    const handlerSelect = (item) => {
        setActiveItem(item);
    };

    const handleDetailsEvents = (reason) => {
        console.log("reason", reason);
        switch (reason) {
            case "save":
                setLoading(true);
                break;
            case "tracing-notification":
                setToast("Tracing results saved in your notes", "success");
                break;
            default:
                break;
        }
    };

    const handleSendForTracingUpdate = () => {
        setTracingUpdate(false);
    };

    const populateDebtorSummaryList = (response) => {
        let { data } = response;
        let accounts_data = {
            account_number: "",
            company_address: "",
            company_name: "",
            department: "",
            email: "",
            employee_number: "",
            employer_address_line1: "",
            employer_address_line2: "",
            employer_address_line3: "",
            employer_email: "",
            employer_tel_no: "",
            id: "",
            identification_number: "",
            legal_case_no: "",
            name: "",
            occupation: "",
            other_income: "",
            pay_day: "",
            pay_period: "",
            payment_period: "",
            phone_home: "",
            phone_mobile: "",
            phone_work: "",
            phone_other: "",
            physical_address: "",
            postal_address: "",
            prefered_language: "",
            proll: "",
            salary_amount: "",
            sallary_cap: "",
            salary_proof_status: "",
            salary_pay_date: "",
            supervisor: "",
            surname: "",
            ptp_minimum: "",
            settlement: "",
            status: "",
            total_expenditure: "",
            phone_home_field: "",
            phone_mobile_field: "",
            phone_work_field: "",
            phone_other_field: "",
            client_id: 0,
            recall_confirmed: "",
            matter_acknowledged: "",
            matter_returned: "",
            fraud_matter: "",
            recalled_reason: null
        };

        setAccountDetailsList({ ...accounts_data });

        if (data != null) {
            setDebtorSummaryList((state) => {
                for (const key in state) {
                    if (key in data) {
                        state[key].value = Number(data[key] ?? 0).toLocaleString(LocaleSettings.locale, {
                            minimumFractionDigits: LocaleSettings.minimumFractionDigits,
                            maximumFractionDigits: LocaleSettings.maximumFractionDigits,
                        });
                    }
                }
                return { ...state };
            });
            setLinkedAccounts(() => {
                if (data.linked_accounts && Array.isArray(data.linked_accounts)) {
                    var linked_accounts = [...data.linked_accounts];
                    delete data.linked_accounts; // deleting to clean the object
                    return linked_accounts;
                }
                return [];
            });

            // storing the raw data
            setAccountDetailsList((state) => {
                for (const key in state) {
                    if (key in data && data[key] != null) {
                        state[key] = data[key];
                    }
                }
                return { ...state };
            });
        }
    };

    const getDebtorSummary = (data) => {
        if (data != null) {
            setLoading(true);
            history.push(data);
        }

        CaseService.debtorCaseSummary({
            account_no: locationState.account_number,
        })
            .then((response) => populateDebtorSummaryList(response))
            .finally(() => {
                setLoading(false);
            });
    };

    const getDebtorCampaignSummary = () => {
        console.log("location state data from debtor detail: ", locationState);
        AgentService.assignCampaignToAgent(locationState.client_id)
            .then((response) => {
                populateDebtorSummaryList(response);
                if (locationState.debtor_name !== response.data.name) {
                    const location = {
                        pathname: `${DASHBOARD}${DEBTOR}/${response.data.name}`,
                        state: {
                            debtor_name: response.data.name,
                            account_number: response.data.account_number,
                            client_id: locationState.client_id,
                            campaign_summary: true,
                        },
                    };
                    history.push(location);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    React.useEffect(() => {
        if (locationState?.account_number != null && loading == true) {
            if (locationState.campaign_summary) {
                getDebtorCampaignSummary();
                return;
            }
            getDebtorSummary(null);
        }
    }, [locationState]);

    React.useEffect(() => {
        if (window.Echo) {
            try {
                window.Echo?.channel("tracing")
                    .subscribed(() => {
                        console.log("You are subscribed");
                    })
                    .listen(".notification", (data) => {
                        if (authState.id === data.user_id) {
                            setTracingUpdate(true);
                            handleDetailsEvents("tracing-notification");
                        }
                    });
            } catch (e) {
                console.log(e + "event listener issue");
            }
        }
    }, [window.Echo]);

    return (
        <div className={style.wrapper_detail}>
            <TitleSection>
                <ButtonBack
                    height={30}
                    width={91}
                    fontSize={15}
                    small_size={12}
                    isCustom
                    customStyle={{
                        textTransform: "uppercase",
                        fontFamily: "Avenir Next Demi Bold",
                    }}
                    onClick={() => history.goBack()}
                >
                    <ArrowBackIcon fontSize="small" /> Back
                </ButtonBack>
                <span style={{ fontWeight: "bold" }}>
                    DEBTOR ACCOUNT DETAILS{" "}
                    {accountDetailsList.fraud_matter > 0 ||
                        (accountDetailsList.recall_confirmed > 0 && <Flag color="error" />)}
                </span>
            </TitleSection>
            <Box padding={"14px 20px"} paddingSmall={"10px 15px"}>
              
                    <Title>
                        DEBTOR SUMMARY <span> - {locationState?.debtor_name} 
                            {" "}{ accountDetailsList?.recalled_reason && <Chip label={accountDetailsList?.recalled_reason} color="secondary" style={{ color: 'white', backgroundColor: 'red' }}/>}
                        </span>
                    </Title>
        
               
                <Grid container spacing={1} wrap="nowrap" alignItems="center" justifyContent="space-between">
                    {Object.values(debtorSummaryList).map((el) => (
                        <Grid key={el.title} md={2} item>
                            <div className={style.innerSummary}>
                                <div className={style.summaryLogo}>
                                    <img
                                        className={el.title === "legal fees" ? style.legalIcon : ""}
                                        src={el.icon}
                                        alt=""
                                    />
                                </div>
                                <div>{el.title}</div>
                                <span>{`R ${el.value}`}</span>
                            </div>
                        </Grid>
                    ))}
                    {/* <Grid md={2} item>
						<div className={style.wrapperChart}>
							<div className={style.chart_section}>
								<Chart />
							</div>
						</div>
					</Grid> */}
                </Grid>
            </Box>

            <div className={style.mainSection}>
                {/* <SwitchComponent
          handler={handlerSelect}
          currentItem={activeItem}
          configList={configList}
        /> */}

                <TabsComponent configList={configList} handler={handlerSelect} currentItem={activeItem} />

                <Box>
                    <DebtorAccountDetails
                        account_details={accountDetailsList}
                        linked_accounts={linkedAccounts}
                        activeItem={activeItem}
                        dispatch={handleDetailsEvents}
                        linkedAccountView={getDebtorSummary}
                    />
                    {activeItem === configList[0] && (
                        <Fees
                            account_status={accountDetailsList?.status}
                            account_no={locationState?.account_number}
                            agent_id={authState?.id}
                            recall_confirmed={accountDetailsList?.recall_confirmed}
                            matter_acknowledged={accountDetailsList?.matter_acknowledged}
                            matter_returned={accountDetailsList?.matter_returned}
                            debtor_name={accountDetailsList?.name}
                            debtor_id={accountDetailsList?.id}
                            debtor_email={accountDetailsList?.email}
                            accountDetailsList={accountDetailsList}
                        />
                    )}

                    {activeItem === configList[1] && (
                        <Contacts account_no={locationState?.account_number} agent_id={authState?.id} />
                    )}

                    <div hidden={activeItem !== configList[2]}>
                        <Notes
                            account_status={accountDetailsList?.status}
                            account_no={locationState?.account_number}
                            agent_id={authState?.id}
                            debtor_id={accountDetailsList?.id}
                            client_id={accountDetailsList?.client_id}
                            dispatch={handleSendForTracingUpdate}
                            tracingUpdate={tracingUpdate}
                            activeItem={activeItem}
                        />
                    </div>

                    <div hidden={activeItem !== configList[3]}>
                        <EmploymentDetails
                            account_status={accountDetailsList?.status}
                            account_details={accountDetailsList}
                            agent_id={authState?.id}
                            dispatch={handleDetailsEvents}
                        />
                    </div>

                    {activeItem === configList[4] && (
                        <AdditionalInfo account_no={locationState?.account_number} agent_id={authState?.id} />
                    )}

                    {activeItem === configList[5] && (
                        <Ptp
                            account_status={accountDetailsList?.status}
                            account_no={locationState?.account_number}
                            agent_id={authState?.id}
                            account_settlement={accountDetailsList.settlement}
                            ptp_minimum={accountDetailsList.ptp_minimum}
                        />
                    )}

                    <div hidden={activeItem !== configList[6]}>
                        <AttachmentInformation
                            account_status={accountDetailsList?.status}
                            account_details={accountDetailsList}
                            agent_id={authState?.id}
                            dispatch={handleDetailsEvents}
                            account_no={locationState?.account_number}
                        />
                    </div>

                    {locationState?.campaign_summary && (
                        <NextDebtorButton variant="contained" onClick={getDebtorCampaignSummary}>
                            Next Debtor
                        </NextDebtorButton>
                    )}
                </Box>
            </div>
        </div>
    );
}

export default DebtorDetail;
