import { Box, CircularProgress, TextField, FormGroup, FormControlLabel, Switch, Tooltip, IconButton } from "@material-ui/core";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import { HelpOutlineOutlined } from '@material-ui/icons'; // Import HelpOutlineOutlined icon from Material-UI


const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    account_no: Yup.string(),
    subject: Yup.string().required("subject is a required field"),
    text: Yup.string().required("text is a required field"),
});

const SendAccountSelectionChoice = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);
    const [selectForCollectionChecked, setSelectForCollectionChecked] = useState(false);

    const [data, setData] = useState({
        account_no,
        subject: "",
        text: "",
        select_for_collection: "N",
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSendAccountSelectionChoice = () => {
        setLoading(true);
        LssmService.sendAccountSelectionChoice({
            ...data
        }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    const handleSelectForCollectionChecked = (e) => {
        setSelectForCollectionChecked(e.target.checked);
        !selectForCollectionChecked
          ? setData({ ...data, select_for_collection: "Y"})
          : setData({ ...data, select_for_collection: "N" });
    };

    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Tooltip title="Sent to inform SBSA on whether the EDC accepts to work the account or not">
                    <Title>
                        Account Selection Choice
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendAccountSelectionChoice}
                >
                    {({ errors }) => (
                        <Form fullWidth>

                            <Box mb={2} display="flex" justifyContent="center">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={selectForCollectionChecked} color="primary" onChange={handleSelectForCollectionChecked} />}
                                        label="Select for Collection?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Subject</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="subject"
                                        name="subject"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.subject}
                                        error={errors.subject ? true : false}
                                        helperText={errors.subject}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>  

                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Comment</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="text"
                                        name="text"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.text}
                                        error={errors.text ? true : false}
                                        helperText={errors.text}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>  

                            <Box mb={2}  display="flex" justifyContent="center">       
                                {loading ? (
                                    <div style={{padding: "3px 30px"}}><CircularProgress /></div>
                                ) : (
                                    <BluePillButton variant="contained" type="submit">
                                        Send Account Selection Choice
                                    </BluePillButton>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendAccountSelectionChoice;
