import { Box, CircularProgress, TextField, FormGroup, Tooltip, IconButton } from "@material-ui/core";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import style from "../../../components/inputs/styles.module.css";
import { ArrowDown } from "../../../common/Layouts";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import { HelpOutlineOutlined } from "@material-ui/icons";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    account_no: Yup.string(),
    comment: Yup.string().required("Comment is a required field"),
    non_service_reason: Yup.string().required("non service reason is a required field"),
    date: Yup.date().required("date is a required field"),
});

const SendWritOfExecutionNonServiceDate = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        account_no,
        date: "",
        comment: "",
        non_service_reason: "",
        non_service_reason_comment: "",
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    
    const handleSendWritOfExecutionNonServiceDate = () => {
        setLoading(true);

        LssmService.sendWritOfExecutionNonServiceDate({
            ...data
        }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };


    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Tooltip title="Sent to inform SBSA when a writ has not been served">
                    <Title>
                        Writ Of Execution Non Service Date
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendWritOfExecutionNonServiceDate}
                >
                    {({ errors }) => (
                        <Form fullWidth>
                            <Box mb={2} display={"flex"} justifyContent={"center"} >
                                <FormGroup>
                                    <div className={styles.title}>Date</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="date"
                                        name="date"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.date}
                                        error={errors.date ? true : false}
                                        helperText={errors.date}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Comment</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="comment"
                                        name="comment"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.comment}
                                        error={errors.comment ? true : false}
                                        helperText={errors.comment}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>   
                 
                            <Box mb={2} display={"flex"} justifyContent={"center"} >
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Non Service Reason</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.non_service_reason ? true : false}
                                                id="non_service_reason"
                                                name="non_service_reason"
                                                helperText={errors.non_service_reason}
                                                className={"select-input-e4"}
                                                value={data.non_service_reason}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Non Service Reason</option>
                                                <option value="01">Address not found</option>
                                                <option value="02">Wrong address provided</option>
                                                <option value="03">No access gained to the property</option>
                                                <option value="04">Property vacant</option>
                                                <option value="05">Occupants advise Customers whereabouts unknown</option>
                                                <option value="06">Other</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>

                            {data.non_service_reason === "06" && (
                                <Box mb={2} paddingX={20}>
                                    <FormGroup>
                                        <div className={styles.title}>Non Service Reason Comment</div>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="non_service_reason_comment"
                                            name="non_service_reason_comment"
                                            customStyle={{
                                                justifyContent: "flex-start",
                                            }}
                                            value={data.non_service_reason_comment}
                                            error={errors.non_service_reason_comment ? true : false}
                                            helperText={errors.non_service_reason_comment}
                                            onChange={handleChange}
                                            isSearch={false}
                                        />
                                    </FormGroup>
                                </Box>    
                            )}
                            
                            <Box mb={2}  display="flex" justifyContent="center">       
                                {loading ? (
                                    <div style={{padding: "3px 30px"}}><CircularProgress /></div>
                                ) : (
                                    <BluePillButton variant="contained" type="submit">
                                        Send Writ Of Execution Non Service Date
                                    </BluePillButton>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendWritOfExecutionNonServiceDate;
