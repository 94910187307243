import { Box, CircularProgress, FormGroup, FormControlLabel, Switch, TextField, Tooltip, IconButton } from "@material-ui/core";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import moment from "moment";
import DatePicker from "components/inputs/DatePicker";
import style from "../../../components/inputs/styles.module.css";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import { HelpOutlineOutlined } from "@material-ui/icons";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    account_no: Yup.string(),
    docs_received: Yup.string().required("Summons Docs Received is a required field"),
    summons_docs_received_date: Yup.date().required("Summons Docs Received Date is a required field"),
});

const SendSummonsDocumentsReceived = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        account_no,
        docs_received: "N",
        summons_docs_received_date: ""
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSendSummonsServed = () => {
        setLoading(true);
        LssmService.sendSummonsDocumentsReceived({
            ...data
        }).then((response) => {
            setLoading(false);
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    const handleChecked = (e) => {
        setChecked(e.target.checked);
        setData({ ...data, docs_received: e.target.checked ? "Y" : "N" });
    };

    return (
        <Box display="flex" justifyContent="center" flexDirection="column" padding="2em">
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Tooltip title="Sent to confirm receipt of documents from SBSA to begin litigation">
                    <Title>
                        Summons Documents Received
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendSummonsServed}
                >
                    {({ errors, handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <Box mb={2} display="flex" justifyContent="center">
                                <FormGroup>
                                    <div className={styles.title}>Summons Docs Received Date</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="summons_docs_received_date"
                                        name="summons_docs_received_date"
                                        value={data.summons_docs_received_date}
                                        error={!!errors.summons_docs_received_date}
                                        helperText={errors.summons_docs_received_date}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2} display="flex" justifyContent="center">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={checked} color="primary" onChange={handleChecked} />}
                                        label="Supporting documents available?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2} display="flex" justifyContent="center">
                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    <BluePillButton variant="contained" type="submit">
                                        Send Summons Documents Received
                                    </BluePillButton>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendSummonsDocumentsReceived;
